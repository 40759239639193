import React from "react"

function PolicyDownloads({ downloadPolicies }){

  function formatURL(url) {
    var newURL = url.replace(/^.*\/\/[^\/]+/, '');
    return newURL;
  }


return (
  <section className="content very-light-blue-background">
  <div className="eighty-spacer"></div>
    <div className="content-block">
      <div className="policy-download-container">
        <ul>
  {downloadPolicies.length
    ? downloadPolicies.map(prod => (
        <li>
        <a href={formatURL(prod.downloadPolicyUpload.mediaItemUrl)} target="_blank" rel="noopener noreferrer">
        <div className="policy-download-title-holder">
        {prod.downloadPolicyTitle}
        </div>
        <div className="policy-download-link-holder">
          <span className="download-icon"></span> Download PDF
        </div>
        </a>
        </li>
   ))
 : null}
      </ul>
      </div>
    </div>
 <div className="eighty-spacer"></div>
 </section>
 )
  }


export default PolicyDownloads
